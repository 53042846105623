<template>
  <GModal v-show="showModal" name="modal" class="modal modal-notification-add-cart">
    <div slot="header">
      <span class="modal-title">
        <img src="/static/img/vm-cola/shopping-bag-new.svg" alt="Add To Bag Sociolla" />
        <div class="text-red">IT'S ALMOST YOURS</div>
        <div class="text">Berhasil ditambahkan ke shopping bag</div>
      </span>
      <!-- <button type="button" class="close" @click.prevent="close"></button> -->
    </div>
    <div slot="body">
      <div v-if="product && Object.keys(product).length" class="detail-product">
        <figure>
          <img v-if="productImage" :src="productImage" :alt="product.name" />
        </figure>
        <div class="desc">
          <div v-if="product.brand" class="brands">{{ product.brand.name }}</div>
          <div v-if="product.name" class="name">{{ product.name }}</div>
          <div
            v-if="combinationGroupName && combinationGroupName.indexOf('Non_specify') < 0"
            class="combination"
            v-html="combinationGroupName"
          ></div>
        </div>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalNotificationAddCart',
  computed: {
    combinationGroupName() {
      return this.$store.state.vmCola.notificationAddCart.combination_group_name
        ? this.$store.state.vmCola.notificationAddCart.combination_group_name.replace(/-/gi, ':')
        : '';
    },
    product() {
      return this.$store.state.vmCola.notificationAddCart.data;
    },
    showModal() {
      return this.$store.state.vmCola.notificationAddCart.isShow;
      // return true
    },
    productImage() {
      return this.product.images[0].url;
    },
  },
  methods: {
    close() {
      this.$store.commit('HIDE_MODAL', 'notificationAddCart');
    },
  },
};
</script>
